import React, { useEffect, useState } from "react";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Superscript from "@tiptap/extension-superscript";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import Image from "@tiptap/extension-image";
import History from "@tiptap/extension-history";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import { FullMenuBar } from "./menuBar";

import { useTranslation } from "react-i18next";
import { LinkTextField } from "components/form/linkTextField";

import { WikiAttachment } from "../extensions/wikiAttachment";
import { SearchWiki, WikiLinkTag } from "../extensions/wikiLink";
import { getVimeoEmbedUrl, Vimeo } from "../extensions/vimeo";

import "../styles.scss";
import { useApolloClient } from "@apollo/client";
import { LINK_TRANSFORMER_QUERY, MINIMAL_WIKI_PAGE_QUERY } from "queries/wiki";
import { Popup } from "components/popup";
import {
  AiOutlineInsertRowAbove,
  AiOutlineInsertRowBelow,
  AiOutlineInsertRowLeft,
  AiOutlineInsertRowRight,
} from "react-icons/ai";
import classNames from "classnames";
import { RiDeleteColumn, RiDeleteRow } from "react-icons/ri";
import { WikiPage } from "generated/graphql";
import { getTextFromMultiLang } from "helpers/multiLang";

export const FullEditor = ({
  initialContent,
  editorRef,
  readOnly,
  topRightElement,
  slugs,
  page,
  onSubPageCreated,
  language,
}: {
  initialContent: string;
  editorRef: React.MutableRefObject<Editor>;
  readOnly: boolean;
  topRightElement: JSX.Element;
  slugs: string[];
  page: Partial<WikiPage>;
  onSubPageCreated: (subPageUrl: string) => void;
  language?: string;
}): JSX.Element => {
  const editor = useEditor({
    onCreate: (object) => {
      // @ts-ignore
      editorRef.current = object.editor;
    },
    extensions: [
      // custom
      WikiAttachment,
      WikiLinkTag,
      Vimeo,
      // core
      Document,
      Dropcursor,
      Gapcursor,
      Text,
      Paragraph,
      // extensions
      Highlight.configure({ multicolor: true }),
      Bold,
      Italic,
      Strike,
      Superscript,
      Link.configure({ openOnClick: false }),
      TableRow,
      TableHeader,
      TableCell,
      Table,
      ListItem,
      OrderedList,
      BulletList,
      Heading.configure({
        levels: [1, 2, 3],
      }),
      Image.configure({
        allowBase64: true,
      }),
      // Undo / Redo functionality
      History,
    ],
    content: initialContent,
    editable: !readOnly,
  });

  const { t } = useTranslation("tiptapMenubar");

  const [currentLinkUrl, setCurrentLinkUrl] = useState("");
  const [currentSelectedText, setCurrentSelectedText] = useState<string>("");

  const [currentAdditionalInput, setCurrentAdditionalInput] = useState<
    string | null
  >(null);

  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onEnter = () => {
    setIsLoading(true);
    if (!editor) return;
    client
      .query({
        query: LINK_TRANSFORMER_QUERY,
        variables: { currentLinkUrl },
        errorPolicy: "all",
        fetchPolicy: "no-cache",
      })
      .then(async (response) => {
        if (response?.errors) {
          setErrorMessage(t("linkErrorMessage"));
        } else {
          await handleSetLink(editor, response.data.linkTransformer);
          setErrorMessage(null);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const onVimeoEnter = (editor) => {
    setIsLoading(true);
    getVimeoEmbedUrl(currentLinkUrl)
      .then((url) => {
        editor.chain().focus().setVideo({ src: url }).run();
        closeAdditionalInput();
      })
      .catch((_) => {
        setErrorMessage(t("vimeoErrorMessage"));
      })
      .finally(() => setIsLoading(false));
  };

  const closeAdditionalInput = () => {
    setIsLoading(false);
    setErrorMessage(null);
    setCurrentAdditionalInput(null);
  };

  const handleSetLink = async (editor: Editor, href: string) => {
    if (currentLinkUrl) {
      if (href.startsWith("/wiki/byId/")) {
        const result = /\/wiki\/byId\/(\d+)/.exec(href) ?? [];
        const wikiId = result[1];

        const wiki = await client.query({
          query: MINIMAL_WIKI_PAGE_QUERY,
          variables: { id: wikiId },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        });
        const selectedText = getCurrentlySelectedText(editor);
        const m = getTextFromMultiLang(language ?? "de");
        const page = {
          href: href,
          title:
            selectedText?.length > 0
              ? selectedText
              : m(wiki.data?.wikiPage?.title, href),
        };
        setWikiLink(editor, page);
      } else {
        const selectedText = getCurrentlySelectedText(editor);
        if (selectedText?.length > 0) {
          // update link
          editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({
              href: href,
            })
            .run();
        } else {
          editor.chain().setLink({ href }).insertContent(href).run();
        }
      }
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    closeAdditionalInput();
  };

  const setWikiLink = (editor, wikiPage) => {
    if (wikiPage) {
      editor
        .chain()
        .insertWikiLinkTag({
          title: wikiPage.title,
          url: wikiPage.href,
        })
        .run();
    }
    closeAdditionalInput();
  };

  useEffect(() => {
    if (readOnly) {
      editor?.setEditable(false);
    } else {
      editor?.setEditable(true);
    }
    // Force a rerender:
    // Without this, our custom extensions are not rendered when the isEditable property changes.
    editor?.createNodeViews();
  }, [readOnly]);

  return (
    <div style={{ minHeight: "calc(100vh - 218px)" }}>
      {editor ? (
        <>
          {readOnly ? null : (
            <div
              className="sticky flex flex-col z-10 w-full py-3 pl-8 pr-1 ml-1 bg-white bg-opacity-100"
              style={{
                top: "157px",
              }}
            >
              <div className="grid flex-wrap-reverse grid-cols-4 gap-x-2">
                <div className="max-w-[91%] lg:max-w-[84%] col-span-3">
                  <FullMenuBar
                    editor={editor}
                    showAdditionalInput={currentAdditionalInput !== null}
                    currentAdditionalInput={currentAdditionalInput}
                    onClickLink={() => {
                      if (currentAdditionalInput == null) {
                        setCurrentAdditionalInput("link");
                      } else {
                        setCurrentAdditionalInput(null);
                      }
                      const previousUrl = editor.getAttributes("link").href;
                      setCurrentLinkUrl(previousUrl);
                    }}
                    onClickWikiLink={() => {
                      if (currentAdditionalInput == null) {
                        setCurrentAdditionalInput("wikiLink");
                      } else {
                        setCurrentAdditionalInput(null);
                      }
                      const previousUrl = editor.getAttributes("wikiLink").href;
                      const selectedText = getCurrentlySelectedText(editor);
                      setCurrentLinkUrl(previousUrl);
                      setCurrentSelectedText(selectedText);
                    }}
                    onClickVimeoLink={() => {
                      if (currentAdditionalInput == null) {
                        setCurrentAdditionalInput("vimeo");
                      } else {
                        setCurrentAdditionalInput(null);
                      }
                      const previousUrl = editor.getAttributes("vimeo").src;
                      const previousUrlPaths = previousUrl?.split("/") ?? [];
                      const previousPlayerId =
                        previousUrlPaths[previousUrlPaths.length - 1] ?? "";
                      const selectedText = getCurrentlySelectedText(editor);
                      setCurrentLinkUrl(previousPlayerId);
                      setCurrentSelectedText(selectedText);
                    }}
                  />
                </div>
                <div className="flex items-start justify-end my-1">
                  {topRightElement}
                </div>
                {currentAdditionalInput !== null ? (
                  <div className="relative max-w-[91%] lg:max-w-[84%] col-span-3 z-10">
                    <div className="absolute w-full px-1 py-1 bg-white border border-t-0 rounded-b">
                      {currentAdditionalInput == "link" ? (
                        <LinkTextField
                          placeholder={t("linkPlaceholder")}
                          value={currentLinkUrl}
                          onChange={setCurrentLinkUrl}
                          onEnter={onEnter}
                          onClose={closeAdditionalInput}
                          isLoading={isLoading}
                          errorMessage={errorMessage}
                        />
                      ) : currentAdditionalInput == "wikiLink" ? (
                        <SearchWiki
                          initialSearchText={currentSelectedText}
                          value={currentLinkUrl}
                          onChange={(wikiPage) => {
                            setWikiLink(editor, wikiPage);
                          }}
                          page={page}
                          slugs={slugs}
                          onClose={closeAdditionalInput}
                          onSubPageCreated={onSubPageCreated}
                        />
                      ) : currentAdditionalInput == "vimeo" ? (
                        <LinkTextField
                          placeholder={t("vimeoPlaceholder")}
                          value={currentLinkUrl}
                          onChange={setCurrentLinkUrl}
                          onEnter={() => onVimeoEnter(editor)}
                          onClose={closeAdditionalInput}
                          isLoading={isLoading}
                          errorMessage={errorMessage}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : (
                  editor.isActive("table") && (
                    <div className="mt-2 border rounded tiptap-editor w-max z-10">
                      <div className="-mr-1 tiptap-editor-header">
                        <Popup
                          content={<p className="py-1">{t("addRowBelow")} </p>}
                        >
                          <AddRowAfterButton editor={editor} />
                        </Popup>
                        <Popup
                          content={<p className="py-1">{t("addRowAbove")} </p>}
                        >
                          <AddRowBeforeButton editor={editor} />
                        </Popup>
                        <Popup
                          content={
                            <p className="py-1">{t("addColumnAfter")} </p>
                          }
                        >
                          <AddColumnAfterButton editor={editor} />
                        </Popup>
                        <Popup
                          content={
                            <p className="py-1">{t("addColumnBefore")} </p>
                          }
                        >
                          <AddColumnBeforeButton editor={editor} />
                        </Popup>
                        <Popup
                          content={<p className="py-1">{t("deleteRow")}</p>}
                        >
                          <DeleteRowButton editor={editor} />
                        </Popup>
                        <Popup
                          content={<p className="py-1">{t("deleteColumn")} </p>}
                        >
                          <DeleteColumnButton editor={editor} />
                        </Popup>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div
                className="relative shadow-bottom w-screen h-1 mr-0.5 left-0"
                style={{ top: "12px", left: "-34px" }}
              ></div>
            </div>
          )}
          <div className="pb-5 z-1 px-10 pt-8">
            <EditorContent editor={editor} />
          </div>
        </>
      ) : null}
    </div>
  );
};

const AddColumnBeforeButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().addColumnBefore().run()}
      className={classNames("menu-item", {})}
    >
      <AiOutlineInsertRowLeft />
    </button>
  );
};

const AddColumnAfterButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().addColumnAfter().run()}
      className={classNames("menu-item", {})}
    >
      <AiOutlineInsertRowRight />
    </button>
  );
};

const AddRowBeforeButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().addRowBefore().run()}
      className="menu-item"
    >
      <AiOutlineInsertRowAbove />
    </button>
  );
};

const AddRowAfterButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().addRowAfter().run()}
      className={classNames("menu-item", {})}
    >
      <AiOutlineInsertRowBelow />
    </button>
  );
};

const DeleteColumnButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().deleteColumn().run()}
      className={classNames("menu-item", {})}
    >
      <RiDeleteColumn />
    </button>
  );
};

const DeleteRowButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().deleteRow().run()}
      className={classNames("menu-item", {})}
    >
      <RiDeleteRow />
    </button>
  );
};

// From https://github.com/ueberdosis/tiptap/issues/369#issuecomment-1025707881
const getCurrentlySelectedText = (editor: Editor): string => {
  const { from, to, empty } = editor.state.selection;

  if (empty) {
    return "";
  }

  return editor.state.doc.textBetween(from, to, " ");
};
